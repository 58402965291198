import { graphql } from 'gatsby';
import React from 'react';
import ChallengeUsPage from '../components/ChallengeUsPage';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
// import apps from "../hooks/useApps"
import SEO from '../components/seo';
import { ResponseState } from '../hooks/useContactForm';
import { SITE_URL } from '../theme/constants';

const ContactUsStructuredData = (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'ContactPage',
      url: `${SITE_URL}/contact-us/`,
      name: 'Contact Us',
    })}
  </script>
);

const IndexPage = () => {
  const [, setRequestResponse] = React.useState(ResponseState.None);

  return (
    <Layout>
      <Navigation headerBackgroundColor="light" transparent={false} />
      <ChallengeUsPage setRequestResponse={setRequestResponse} />
      <Footer noFooterCard />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Contact Us | Questions & Project Enquiries to the Web & Mobile Development Agency Stormotion"
    description="Please share the details of your project. We’ll contact you within a couple of hours to schedule a meeting to discuss your goals, project requirements, and timeframes."
  >
    {ContactUsStructuredData}
  </SEO>
);

export default React.memo(IndexPage);
